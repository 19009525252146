<template>
    <div>
        <b-row>
            <b-col class="d-flex justify-content-end my-2">
                <b-button :disabled="rowSelected.length < 1" variant="pgiBtn" class="mr-2" @click="handleTerimaMultiple">
                    <i class="fa fa-check"></i>
                    Terima
                </b-button>
                <!-- <b-button :disabled="rowSelected.length < 1" variant="danger" class="mr-2" @click="handleVoidMultiple">
                    <i class="fa fa-times"></i>
                    Void
                </b-button> -->
            </b-col>
        </b-row>



        <b-table small bordered hover show-empty :items="pembelian.detail" :fields="fields" responsive class="mb-3">
            <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading data...</strong>
                </div>
            </template>

            <template v-slot:head(check)="row">
                <b-form-checkbox :checked="checkedHeader" @change="handleSelectAll">
                </b-form-checkbox>
            </template>
            <template v-slot:cell(check)="row">
                <b-form-checkbox v-if="row.item.status === 1" v-model="row.item.checked" @change="handleSelectItem(row)">
                </b-form-checkbox>
                <b-form-checkbox v-else disabled>
                </b-form-checkbox>
            </template>
            <template v-slot:cell(jumlah)="row">
                <div>
                    <b class="mr-1">{{ row.item.jumlah }}</b>
                    <small>{{ row.item.satuan }}</small>
                </div>
            </template>
            <template v-slot:cell(cabang)="row">
                <div>
                    <span>{{ row.item.cabang ? row.item.cabang.name : "Pusat" }}</span>
                </div>
            </template>
            <template v-slot:empty> Tidak ada data jenis barang. </template>
            <template v-slot:cell(barang)="row">
                <small class="d-block">Kode : <b> {{ row.item.tipe_barang.kode_barang }}</b></small>
                <small>Tipe : <b> {{ row.item.tipe_barang.tipe }}</b></small>
            </template>
            <template v-slot:cell(harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.harga) }}</div>
                </div>
            </template>
            <template v-slot:cell(total_harga)="row">
                <div class="d-flex align-items-center justify-content-between">
                    <div>Rp.</div>
                    <div class="text-end">{{ $helper.rupiah(row.item.total_harga) }}</div>
                </div>
            </template>
            <template v-slot:cell(status)="row">
                <div class="no-wrap">
                    <b-badge variant="primary" v-if="row.item.status == 1">Sudah dibayar</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 2">Void</b-badge>
                    <b-badge variant="primary" v-if="row.item.status == 3">Selisih</b-badge>
                    <b-badge variant="success" v-if="row.item.status == 5">Diterima</b-badge>
                    <b-badge variant="warning" v-if="row.item.status == 6">Komplain</b-badge>
                    <b-badge variant="danger" v-if="row.item.status == 7">Ditolak</b-badge>
                </div>
            </template>
            <template v-slot:cell(action)="row">
                <div v-if="pembelian.flag == 2">
                    <div v-if="row.item.status === 1">
                        <b-button @click="handleTerimaSingle(row.item)" class="ml-1" size="sm" variant="primary"
                            v-b-tooltip.hover title="Terima">
                            <i class="fa fa-check"></i>
                        </b-button>
                        <!-- <b-button @click="handleVoidSingle(row.item)" class="ml-1" size="sm" variant="danger"
                            v-b-tooltip.hover title="Void">
                            <i class="fa fa-times"></i>
                        </b-button> -->
                        <b-button @click="openModalSelisi(row.item)" class="ml-1" size="sm" variant="warning"
                            v-b-tooltip.hover title="Selisih">
                            <i class="fa fa-exchange"></i>
                        </b-button>
                    </div>
                    <div v-if="row.item.status === 2">
                        <b-badge variant="danger" v-if="row.item.status == 2">Item Void</b-badge>
                    </div>
                    <div v-if="row.item.status === 3">
                        <b-badge variant="warning" v-if="row.item.status == 3">Item Selisih</b-badge>
                    </div>
                    <div v-if="row.item.status === 5">
                        <b-badge variant="success" v-if="row.item.status == 5">Diterima</b-badge>
                    </div>
                </div>
                <div v-if="pembelian.flag == 3">
                    <b-badge variant="success">Diterima</b-badge>
                </div>
            </template>
        </b-table>
        <b-modal id="m-selisih" size="md" title="Selisih Barang" no-close-on-backdrop no-close-on-esc hide-footer>
            <div class="mb-3" v-if="detailRowSelected">
                <div class="row">
                    <div class="col-sm-4">No. Barang</div>
                    <div class="col-sm-8"> : <b>{{ detailRowSelected.nomer_barang || "-" }}</b> </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">Tipe</div>
                    <div class="col-sm-8"> : <b>{{ detailRowSelected.tipe }}</b> </div>
                </div>
            </div>
            <ul class="list-group list-group-flush">
                <li class="list-group-item d-flex px-0 justify-content-between align-items-center">
                    <span>Jumlah Pembelian</span>
                    <b>{{ detailRowSelected.jumlah }}</b>
                </li>
                <li class="list-group-item d-flex px-0 justify-content-between align-items-center">
                    <span>Barang Diterima</span>
                    <b-form-input v-model="jumlahAktual" @input="handleAktualCount" placeholder="aktual" type="number"
                        class="input-selisih" />
                </li>
                <li class="list-group-item d-flex px-0 justify-content-between align-items-center">
                    <span v-if="jumlahSelisih > 0">kekurangan barang</span>
                    <span v-if="jumlahSelisih < 0">kelebihan barang</span>
                    <span v-if="jumlahSelisih == 0">barang sesuai</span>
                    <b class="text-danger">
                        {{ jumlahSelisih * -1 || "-" }}
                    </b>
                </li>
            </ul>
            <div class="mt-3 d-flex align-items-center justify-content-between">
                <div>
                    <small v-if="jumlahSelisih < 0" class="text-danger">Jumlah barang diterima melebihi jumlah
                        pembelian!</small>
                </div>
                <b-button :disabled="loading || jumlahSelisih < 0" @click="handleSelisihBarang" variant="pgiBtn">
                    <i v-if="loading" class="fa fa-spin fa-spinner"></i>
                    Simpan
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axiosClient from '../../config/api';

export default {
    props: ['pembelian'],
    data() {
        return {
            loading: false,
            checkedHeader: false,
            files_invoice: [],
            files_bukti_pembayaran: [],
            rowSelected: [],
            detailRowSelected: {
                detail_id: null,
                jumlah: null,
                nomer_barang: null,
                tipe: null,
            },
            fields: [
                { key: "check", label: "" },
                { key: "cabang", label: "Cabang" },
                { key: "barang", label: "Info Barang" },
                { key: "jumlah", label: "Jumlah" },
                { key: "harga", label: "Harga" },
                { key: "total_harga", label: "Total Harga" },
                { key: "status", label: "Status" },
                { key: "action", label: "Aksi" },
            ],
            jumlahSelisih: null,
            jumlahAktual: null,
        }
    },
    methods: {
        handleSelectAll(value) {
            this.checkedHeader = value
            const newData = this.pembelian.detail;
            this.pembelian.detail = newData.map(item => {
                if (item.status === 1) {
                    if (value) {
                        item.checked = true
                        this.rowSelected = this.pembelian.detail.map(item => {
                            return {
                                id: item.id
                            }
                        })
                    } else {
                        item.checked = false
                        this.rowSelected = []
                    }
                }
                return item
            })

        },
        handleSelectItem(value) {
            const items = this.rowSelected.find(v => v.id === value.item.id)
            if (!items) {
                const x = [...this.rowSelected, value.item]
                this.rowSelected = x.map(item => {
                    return {
                        id: item.id
                    }
                })
                this.checkedHeader = false
            } else {
                console.log("DATA")
                this.rowSelected = this.rowSelected.filter(rs => rs.id !== value.item.id)
            }
        },
        handleTerimaMultiple() {
            const f = this.pembelian.detail.filter(i => i.checked)
            const form = {
                id: f.map(i => i.id),
                status: 5
            }
            this.$swal({
                title: "Konfirmasi",
                text: "Anda yakin akan menerima data pembelian ini?",
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const r = await this.$store.dispatch("updateStatusPembelianMultiple", form)
                    if (r) {
                        this.$helper.toastSucc(this, "Sukses memproses data");
                        await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
                    } else {
                        this.$helper.toastErr(this, "Gagal memproses data");
                    }
                    this.rowSelected = []
                    this.checkedHeader = false
                }
            });
        },
        handleTerimaSingle(value) {
            const form = {
                id: value.id,
                status: 5
            }
            this.$swal({
                title: "Konfirmasi",
                text: `Anda yakin akan menerima  ${value.nomer_barang}?`,
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const r = await this.$store.dispatch("updateStatusPembelianSingle", form)
                    if (r) {
                        this.$helper.toastSucc(this, "Sukses memproses data");
                        await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
                    } else {
                        this.$helper.toastErr(this, "Gagal memproses data");
                    }
                    this.rowSelected = []
                    this.checkedHeader = false
                }
            });
        },
        handleVoidSingle(value) {
            const form = {
                id: value.id,
                status: 2
            }
            this.$swal({
                title: "Konfirmasi",
                text: `Apakah tidak menerima barang ${value.nomer_barang}?`,
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const r = await this.$store.dispatch("updateStatusPembelianSingle", form)
                    if (r) {
                        this.$helper.toastSucc(this, "Sukses memproses data");
                        await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
                    } else {
                        this.$helper.toastErr(this, "Gagal memproses data");
                    }
                    this.rowSelected = []
                    this.checkedHeader = false
                }
            });
        },
        handleVoidMultiple() {
            const form = {
                id: this.rowSelected.map(i => i.id),
                status: 2
            }
            this.$swal({
                title: "Konfirmasi",
                text: "Apakah anda tidak menerima produk?",
                type: "danger",
                showCancelButton: true,
                confirmButtonColor: "#1e73be",
                cancelButtonText: "Batal",
                confirmButtonText: "Ya, Proses",
                closeOnConfirm: false,
                closeOnCancel: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const r = await this.$store.dispatch("updateStatusPembelianMultiple", form)
                    if (r) {
                        this.$helper.toastSucc(this, "Sukses memproses data");
                        await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
                    } else {
                        this.$helper.toastErr(this, "Gagal memproses data");
                    }
                    this.rowSelected = []
                    this.checkedHeader = false
                }
            });
        },
        openModalSelisi(data) {
            // console.log(data)
            this.detailRowSelected = data;
            this.$bvModal.show("m-selisih");
        },
        handleAktualCount(v) {
            const value = parseInt(v)
            const jumlah = this.detailRowSelected.jumlah
            const selisih = jumlah - value
            this.jumlahSelisih = selisih
        },
        async handleSelisihBarang() {
            const form = {
                id: this.detailRowSelected.id,
                status: 3,
                selisih: this.jumlahSelisih
            }
            try {
                this.loading = true
                const response = await axiosClient({
                    method: 'POST',
                    url: 'transaksi/pembelian/update/detail',
                    data: form
                })
                if (response.status === 200) {
                    await this.$store.dispatch("getDetailPembelian", this.$route.params.id)
                    this.$bvModal.hide("m-selisih");
                    this.$helper.toastSucc(
                        this,
                        `Sukses membuat selisih barang`
                    );

                }
                this.jumlahSelisih = null;
                this.jumlahAktual = null;
            } catch (error) {
                this.$helper.toastErr(
                    this,
                    error.message
                );
            } finally {
                this.loading = false
            }
        }
    },
}
</script>